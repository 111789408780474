<template>
       <div class='empty-text'>
        <div class="n-result-icon">
          <svg v-if="type==='empty'"
            style="width: 30px; height: 30px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
          >
            <circle fill="#FFCB4C" cx="18" cy="17.018" r="17"></circle>
            <path
              fill="#65471B"
              d="M14.524 21.036c-.145-.116-.258-.274-.312-.464-.134-.46.13-.918.59-1.021 4.528-1.021 7.577 1.363 7.706 1.465.384.306.459.845.173 1.205-.286.358-.828.401-1.211.097-.11-.084-2.523-1.923-6.182-1.098-.274.061-.554-.016-.764-.184z"
            ></path>
            <ellipse
              fill="#65471B"
              cx="13.119"
              cy="11.174"
              rx="2.125"
              ry="2.656"
            ></ellipse>
            <ellipse
              fill="#65471B"
              cx="24.375"
              cy="12.236"
              rx="2.125"
              ry="2.656"
            ></ellipse>
            <path
              fill="#F19020"
              d="M17.276 35.149s1.265-.411 1.429-1.352c.173-.972-.624-1.167-.624-1.167s1.041-.208 1.172-1.376c.123-1.101-.861-1.363-.861-1.363s.97-.4 1.016-1.539c.038-.959-.995-1.428-.995-1.428s5.038-1.221 5.556-1.341c.516-.12 1.32-.615 1.069-1.694-.249-1.08-1.204-1.118-1.697-1.003-.494.115-6.744 1.566-8.9 2.068l-1.439.334c-.54.127-.785-.11-.404-.512.508-.536.833-1.129.946-2.113.119-1.035-.232-2.313-.433-2.809-.374-.921-1.005-1.649-1.734-1.899-1.137-.39-1.945.321-1.542 1.561.604 1.854.208 3.375-.833 4.293-2.449 2.157-3.588 3.695-2.83 6.973.828 3.575 4.377 5.876 7.952 5.048l3.152-.681z"
            ></path>
            <path
              fill="#65471B"
              d="M9.296 6.351c-.164-.088-.303-.224-.391-.399-.216-.428-.04-.927.393-1.112 4.266-1.831 7.699-.043 7.843.034.433.231.608.747.391 1.154-.216.405-.74.546-1.173.318-.123-.063-2.832-1.432-6.278.047-.257.109-.547.085-.785-.042zm12.135 3.75c-.156-.098-.286-.243-.362-.424-.187-.442.023-.927.468-1.084 4.381-1.536 7.685.48 7.823.567.415.26.555.787.312 1.178-.242.39-.776.495-1.191.238-.12-.072-2.727-1.621-6.267-.379-.266.091-.553.046-.783-.096z"
            ></path>
          </svg>
        </div>
        <p style="margin-top: 10px">{{tipsText}}</p>
       </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: 'empty'
    },
    tipsText: {
      type: String,
      default: '没有相关名称数据'
    }
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  watch: {
  },
  methods: {

  },
  components: {}
}
</script>
<style lang='scss' scoped>
.empty-text {
  margin-top: 20px;
  color: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .n-result-icon {
    margin: 0 auto;
    height: 30px;
  }
}
</style>
